import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0d159258 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _044e440c = () => interopDefault(import('../pages/happy-breakfast.vue' /* webpackChunkName: "pages/happy-breakfast" */))
const _18844e45 = () => interopDefault(import('../pages/happy-fruit.vue' /* webpackChunkName: "pages/happy-fruit" */))
const _4c90a25b = () => interopDefault(import('../pages/happy-lunch.vue' /* webpackChunkName: "pages/happy-lunch" */))
const _763e3ff7 = () => interopDefault(import('../pages/happy-meetings.vue' /* webpackChunkName: "pages/happy-meetings" */))
const _3629cee4 = () => interopDefault(import('../pages/happy-moments.vue' /* webpackChunkName: "pages/happy-moments" */))
const _15397058 = () => interopDefault(import('../pages/happy-takeaway.vue' /* webpackChunkName: "pages/happy-takeaway" */))
const _0d59a247 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0b419471 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _36c6d6f7 = () => interopDefault(import('../pages/orders.vue' /* webpackChunkName: "pages/orders" */))
const _d44e57ae = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _53be5e56 = () => interopDefault(import('../pages/tak-for-din-henvendelse.vue' /* webpackChunkName: "pages/tak-for-din-henvendelse" */))
const _2556335d = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _2f83238a = () => interopDefault(import('../pages/posts/concept.vue' /* webpackChunkName: "pages/posts/concept" */))
const _406b5157 = () => interopDefault(import('../pages/posts/invite.vue' /* webpackChunkName: "pages/posts/invite" */))
const _11003bd6 = () => interopDefault(import('../pages/user-settings/addresses.vue' /* webpackChunkName: "pages/user-settings/addresses" */))
const _4205af19 = () => interopDefault(import('../pages/user-settings/corporate.vue' /* webpackChunkName: "pages/user-settings/corporate" */))
const _55ae36c1 = () => interopDefault(import('../pages/user-settings/coupons.vue' /* webpackChunkName: "pages/user-settings/coupons" */))
const _0c7051e1 = () => interopDefault(import('../pages/user-settings/orders.vue' /* webpackChunkName: "pages/user-settings/orders" */))
const _dc75d18c = () => interopDefault(import('../pages/user-settings/payment.vue' /* webpackChunkName: "pages/user-settings/payment" */))
const _0fa63546 = () => interopDefault(import('../pages/user-settings/profile.vue' /* webpackChunkName: "pages/user-settings/profile" */))
const _72b40702 = () => interopDefault(import('../pages/user-settings/sharedmeals.vue' /* webpackChunkName: "pages/user-settings/sharedmeals" */))
const _7f3d5883 = () => interopDefault(import('../pages/user-settings/support.vue' /* webpackChunkName: "pages/user-settings/support" */))
const _0653858a = () => interopDefault(import('../pages/user-settings/terms.vue' /* webpackChunkName: "pages/user-settings/terms" */))
const _43b1ff30 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _09ad34f7 = () => interopDefault(import('../pages/order/_uuid.vue' /* webpackChunkName: "pages/order/_uuid" */))
const _6fe5eb7d = () => interopDefault(import('../pages/payment-verify/_uuid.vue' /* webpackChunkName: "pages/payment-verify/_uuid" */))
const _b92e176c = () => interopDefault(import('../pages/restaurant/_slug.vue' /* webpackChunkName: "pages/restaurant/_slug" */))
const _1b9d0066 = () => interopDefault(import('../pages/supplier-chain/_slug.vue' /* webpackChunkName: "pages/supplier-chain/_slug" */))
const _01699299 = () => interopDefault(import('../pages/supplier/_slug.vue' /* webpackChunkName: "pages/supplier/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _0d159258,
    name: "checkout"
  }, {
    path: "/happy-breakfast",
    component: _044e440c,
    name: "happy-breakfast"
  }, {
    path: "/happy-fruit",
    component: _18844e45,
    name: "happy-fruit"
  }, {
    path: "/happy-lunch",
    component: _4c90a25b,
    name: "happy-lunch"
  }, {
    path: "/happy-meetings",
    component: _763e3ff7,
    name: "happy-meetings"
  }, {
    path: "/happy-moments",
    component: _3629cee4,
    name: "happy-moments"
  }, {
    path: "/happy-takeaway",
    component: _15397058,
    name: "happy-takeaway"
  }, {
    path: "/login",
    component: _0d59a247,
    name: "login"
  }, {
    path: "/maintenance",
    component: _0b419471,
    name: "maintenance"
  }, {
    path: "/orders",
    component: _36c6d6f7,
    name: "orders"
  }, {
    path: "/privacy-policy",
    component: _d44e57ae,
    name: "privacy-policy"
  }, {
    path: "/tak-for-din-henvendelse",
    component: _53be5e56,
    name: "tak-for-din-henvendelse"
  }, {
    path: "/user",
    component: _2556335d,
    name: "user"
  }, {
    path: "/posts/concept",
    component: _2f83238a,
    name: "posts-concept"
  }, {
    path: "/posts/invite",
    component: _406b5157,
    name: "posts-invite"
  }, {
    path: "/user-settings/addresses",
    component: _11003bd6,
    name: "user-settings-addresses"
  }, {
    path: "/user-settings/corporate",
    component: _4205af19,
    name: "user-settings-corporate"
  }, {
    path: "/user-settings/coupons",
    component: _55ae36c1,
    name: "user-settings-coupons"
  }, {
    path: "/user-settings/orders",
    component: _0c7051e1,
    name: "user-settings-orders"
  }, {
    path: "/user-settings/payment",
    component: _dc75d18c,
    name: "user-settings-payment"
  }, {
    path: "/user-settings/profile",
    component: _0fa63546,
    name: "user-settings-profile"
  }, {
    path: "/user-settings/sharedmeals",
    component: _72b40702,
    name: "user-settings-sharedmeals"
  }, {
    path: "/user-settings/support",
    component: _7f3d5883,
    name: "user-settings-support"
  }, {
    path: "/user-settings/terms",
    component: _0653858a,
    name: "user-settings-terms"
  }, {
    path: "/",
    component: _43b1ff30,
    name: "index"
  }, {
    path: "/order/:uuid?",
    component: _09ad34f7,
    name: "order-uuid"
  }, {
    path: "/payment-verify/:uuid?",
    component: _6fe5eb7d,
    name: "payment-verify-uuid"
  }, {
    path: "/restaurant/:slug?",
    component: _b92e176c,
    name: "restaurant-slug"
  }, {
    path: "/supplier-chain/:slug?",
    component: _1b9d0066,
    name: "supplier-chain-slug"
  }, {
    path: "/supplier/:slug?",
    component: _01699299,
    name: "supplier-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
